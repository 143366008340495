import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CButton } from '@coreui/react';
import { StyledEngineProvider } from '@mui/material/styles';

export default function BasicModal(props) {
  // Для QuickProductAddition.js та інших
  const style_1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  // Для AddProductToOrder.js
  const style_2 = {
    maxHeight: "90vh",
    overflowY: "auto",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 1600,
    // minWidth: 1500,
    maxWidth: 1500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // const style = (props.type === "orders") ? style_2 : style_1;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.show != undefined && !props.show && open) {
      setOpen(false)
      props.actionShowModal(true)
    };
  }, [props.show, open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); props.actionShowModal(false) };
  const [subItems, setSubItems] = React.useState({});

  useEffect(() => {
    if (props.obj != undefined)
      setSubItems(props.obj.row);
  }, [props.obj]);

  //зміна стану open в залежності від props

  React.useEffect(() => {
    if (props.show) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [props.show])

  // Функція для відкриття модального вікна та запиту за товарами
  const funcModal = () => {
    handleOpen();
    props.actionShowModal(true)
    // Зробити запит за товарами, які додані до order_products?order={id}
    if (props.setGetOP != undefined) {
      props.setGetOP(true);
    };
  };

  return (
    <div>
      <CButton
        color={(props.btn_color != undefined) ? props.btn_color : 'secondary'} onClick={funcModal}
        title={(props.btn_title != undefined) ? props.btn_title : ''}
      >
        {/* <CButton color="link" onClick={funcModal}> */}
        {(props.btn_name != undefined) ? props.btn_name : 'Додати'}
      </CButton>
      <StyledEngineProvider injectFirst>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {
            (props.type === "orders") ?
              // Для AddProductToOrder.js
              <Box className="orders-modal">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="orders-modal__header">

                </Typography>
                {(props.content != undefined) ? props.content : 'Test content'}
              </Box>
              :
              // Для QuickProductAddition.js та інших
              <Box sx={style_1}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {(props.title != undefined) ? props.title : ''}
                </Typography>
                {(props.content != undefined) ? props.content : 'Test content'}
              </Box>
          }
        </Modal>
      </StyledEngineProvider>
    </div>
  );
}