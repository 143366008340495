// Функція для приведення до локального часу

export const useConvertToLocalTime = (date) => {
   const parseDate = new Date(date);
   const hours = parseDate.getUTCHours();
   const minutes = parseDate.getUTCMinutes();
   const seconds = parseDate.getUTCSeconds();

   if (hours === 0 && minutes === 0 && seconds === 0) {
      return parseDate.toLocaleDateString(); // Вивести лише дату
   };

   return parseDate.toLocaleString(); // Вивести повний формат (дата + час)

   // return new Date(date).toLocaleString();
};