/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useLocalStorage from '../store/useLocalStorage';


// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/pre_orders';

const status = [
    { value: 'created', label: 'Створено клієнтом' },
    { value: 'aproved', label: 'Підтверджено' },
    { value: 'comlated', label: 'Виконано' }
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    { value: 'bank', label: 'Мульти оплата' }
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    account: '',
    status: '',
    description: '',
    dateOrder: null,
    curency: '',
    paymentMethod: '',
    sumOrdered: 0,
    curencyRate: 0,
    discountTotal: 0,
    discountPercent: 0,
    shippingAddressStreet: '',
    shippingAddressCity: '',
    shippingAddressBuilding: '',
    shippingAddressCountry: '',
    shippingAddressDate: '',
    synced: false
};



const FormOrders = (props) => {
    const editorRef = useRef(null);
    const data = props.data;
    const navigate = useNavigate();

    // Ключ для Editor
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

    const [values, setValues] = useState(objOrderData);
    const [selectItems, setSelectItems] = useState({
        account: {},
        status: status[0],
        paymentMethod: paymentMethod[0],
        curency: curency[0],
    });
    //блок для завантаження ключа tiny
    const watchedItem = useLocalStorage('tinyKey')
    const [tinyKey, setTinyKey] = useState(watchedItem || '')
    /////////////////////////////////

    useEffect(() => {
        if (data != null) {


            setValues({
                ...values,
                account: data.account.id,
                status: data.status,
                description: data.description,
                dateOrder: data.dateOrder,
                curency: data.curency,
                paymentMethod: data.paymentMethod,
                sumOrdered: data.sumOrdered,
                curencyRate: data.curencyRate,
                discountTotal: data.discountTotal,
                discountPercent: data.discountPercent,
                shippingAddressStreet: data.shippingAddressStreet,
                shippingAddressCity: data.shippingAddressCity,
                shippingAddressBuilding: data.shippingAddressBuilding,
                shippingAddressCountry: data.shippingAddressCountry,
                shippingAddressDate: data.shippingAddressDate,
                synced: false,
            });
            let s = status.map((v, k) => {
                if (data.status == v.value)
                    return v;
            });
            let pm = paymentMethod.map((v, k) => {
                if (data.paymentMethod == v.value)
                    return v;
            });
            let c = curency.map((v, k) => {
                if (data.curency == v.value)
                    return v;
            });
            setSelectItems({
                ...selectItems,
                account: {
                    label: data.account.name,
                    value: data.account.id,
                },
                status: s,
                paymentMethod: pm,
                curency: c,
            });
        }
    }, [data]);

    useEffect(() => {
        if (watchedItem) {
            setTinyKey(watchedItem)
        }
    }, [watchedItem])

    const handleInputChange = (e) => {
        console.log(e);
        const { name, value, type } = e.target;
        setValues({
            ...values,
            [name]: type === "number" ? parseFloat(value, 10) : value
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';

        if (values.account == '') {
            v = false;
            vError += "-> Контрагент\n";
        }
        if (values.status == '') {
            v = false;
            vError += "-> Статус\n";
        }
        if (values.paymentMethod == '') {
            v = false;
            vError += "-> Метод оплати\n";
        }
        if (values.curency == '') {
            v = false;
            vError += "-> Валюта\n";
        }

        if (!v) {
            alert("Ви не заповнили обовязкові поля:\n" + vError);
        }



        if (v && editorRef.current) {
            console.log(editorRef.current.getContent());
            setValues({
                ...values,
                ['desciption']: editorRef.current.getContent(),
                ['account']: (typeof values.account != 'string') ? `/api/accounts/${values.account}` : values.account
            });
            // const article = values;

            if (data != undefined) {
                const response = AxiosInstance.put(`${urlApi}/${data.id}`, values);
            } else {
                const response = AxiosInstance.post(urlApi, values).then((response) => {

                    if (response.status == 201) {
                        return navigate(`/orders/update/${response.data.id}`);
                    }
                });
            }
        }
    }


    /** Контрагенти */
    const [selectedOption, setSelectedOption] = useState(null);
    const loadOptionsAcc = async (inputValue) => {
        return await AxiosInstance.get('/accounts?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };
    const handleChange = (value, name) => {

        setValues({
            ...values,
            [name]: value.value
        });
        setSelectItems({
            ...selectItems,
            [name]: value
        });
    };

    const datePicker = (value) => {
        let formattedDate = value.toISOString().slice(0, 10);
        console.log(typeof formattedDate);
        // values['dateOrder'] = formattedDate;
        let name = 'dateOrder';
        setValues({
            ...values,
            [name]: formattedDate
        });
        console.log(values);
    }



    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsAcc}
                            // defaultOptions
                            value={selectItems.account}
                            // onChange={handleChange}
                            className="flex-grow-1"
                            name="account"
                            // defaultOptions={values.account} 
                            onChange={(value) => { handleChange(value, 'account') }}
                            classNamePrefix='account'
                            styles={{ border: '1px solid red' }}

                        />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Статус замовлення</CFormLabel>
                        <Select options={status} value={selectItems.status} onChange={(value) => { handleChange(value, 'status') }} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Метод оплати</CFormLabel>
                        <Select options={paymentMethod} value={selectItems.paymentMethod} onChange={(value) => { handleChange(value, 'paymentMethod') }} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Дата виконання</CFormLabel>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                value={values.dateOrder} 
                                onChange={datePicker}  
                                inputFormat="yyyy-MM-dd"  
                                // renderInput={(params) => <TextField {...params} />} 
                            />
                        </LocalizationProvider> */}
                        <DatePicker
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            selected={(values.dateOrder == null) ? new Date() : new Date(values.dateOrder)}
                            onChange={(date) => datePicker(date)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Сума</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='sumOrdered' disabled={true} value={values.sumOrdered} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Курс</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='curencyRate' value={values.curencyRate} onChange={handleInputChange} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1" name='curency' value={values.curency} onChange={handleInputChange}>Валюта</CFormLabel>
                        <Select options={curency} value={selectItems.curency} onChange={(value) => { handleChange(value, 'curency') }} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Знижка %</CFormLabel>
                        <CFormInput type="number" id="exampleFormControlInput2" name='dateOrder' value={values.dateOrder} onChange={handleInputChange} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Країна</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCountry' value={values.shippingAddressCountry} onChange={handleInputChange} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Місто</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressCity' value={values.shippingAddressCity} onChange={handleInputChange} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Вулиця</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressStreet' value={values.shippingAddressStreet} onChange={handleInputChange} />
                    </div>
                    <div className='col order-input'>
                        <CFormLabel htmlFor="exampleFormControlInput1">Будинок</CFormLabel>
                        <CFormInput type="text" id="exampleFormControlInput2" name='shippingAddressBuilding' value={values.shippingAddressBuilding} onChange={handleInputChange} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>

                            <Editor
                                // apiKey={apiKey}
                                apiKey={tinyKey}
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={(values != undefined) ? values.description : ''}
                                init={{
                                    height: 500,
                                    // menubar: false,
                                    menubar: 'edit view insert format tools',
                                    // plugins: [
                                    //   'advlist autolink lists link image charmap print preview anchor',
                                    //   'searchreplace visualblocks code fullscreen',
                                    //   'insertdatetime media table paste code help wordcount'
                                    // ],
                                    plugins: [
                                        'advlist', 'autolink',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
                                    ],
                                    // toolbar: 'undo redo | formatselect | ' +
                                    //   'bold italic backcolor | alignleft aligncenter ' +
                                    //   'alignright alignjustify | bullist numlist outdent indent | ' +
                                    //   'removeformat | help',
                                    toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                                        'backcolor forecolor |' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | table help',
                                    font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
}

export default FormOrders;