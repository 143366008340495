import { Fragment, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import AxiosInstance, { isCancel } from "src/components/Axios";
import Spinner from 'react-bootstrap/Spinner'
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CForm, CInputGroup, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormCheck } from '@coreui/react';
import { GiConsoleController } from "react-icons/gi";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { HiOutlineDownload } from "react-icons/hi";
import { sortAlphabetical } from "src/components/store/sortAlphabetical";


const urlApi_1 = '/products';
const urlApi_2 = '/categories';

const Prices = () => {
    const [products, setProducts] = useState([]);
    const [loader, setLoader] = useState(true);
    const [priceGT, setPrcieGT] = useState(0);
    const [show, setShow] = useState(true);
    const [category, setCategory] = useState([]);
    const [data, setData] = useState([]);
    const [filetredCategories, setFilteredCategories] = useState([]);
    const productsControllerRef = useRef(null);
    const categoriesControllerRef = useRef(null);

    const getProducts = async () => {
        // Скасувати попередній запит
        if (productsControllerRef.current) {
            productsControllerRef.current.abort();
        };
        productsControllerRef.current = new AbortController();

        let params = {
            pagination: false,
            "g[0]": "product:read",
            "g[1]": "product:admin",
            "g[2]": "product:category",
        };

        if (show === true) {
            params['show'] = true;
        } else if (priceGT >= 0) {
            params['price[gt]'] = 0;
        };

        try {
            setLoader(true);
            const response = await AxiosInstance.get(urlApi_1, {
                params: params,
                signal: productsControllerRef.current.signal,
            });
            // console.log("get /products response.data['hydra:member']", response.data['hydra:member']);
            setProducts(response.data['hydra:member']);
        } catch (error) {
            if (isCancel(error)) {
                // console.log("Запит за товарами скасовано: ", error.message);
            } else {
                console.error("Помилка запиту за товарами: ", error);
            };
        } finally {
            setLoader(false);
        };
    };

    const getCategories = async () => {
        // Скасувати попередній запит
        if (categoriesControllerRef.current) {
            categoriesControllerRef.current.abort();
        };
        categoriesControllerRef.current = new AbortController();

        let params = {
            pagination: false,
        };

        try {
            const response = await AxiosInstance.get(urlApi_2, {
                params: params,
                signal: categoriesControllerRef.current.signal,
            });

            // console.log("get /categories response.data['hydra:member']", response.data['hydra:member']);
            setCategory(response.data['hydra:member']);
        } catch (error) {
            if (isCancel(error)) {
                // console.log("Запит за категоріями скасований", error.message);
            } else {
                console.error("Помилка запиту за категоріями: ", error);
            };
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            await Promise.all([getCategories(), getProducts()]);
            setLoader(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        getProducts();
    }, [show]);

    useEffect(() => {
        // console.log('data', data);
        if (data.length > 0) {
            const filtered = data.filter(el => el.products.length > 0);
            // console.log('filtered', filtered);
            const sorted = sortAlphabetical(filtered, "cat_name");
            setFilteredCategories(sorted);
        };
    }, [data]);

    useEffect(() => {
        if (products.length > 0) {
            let newArray = [];
            // console.log(products);
            // console.log(category);
            for (let i = 0; i < category.length; i++) {
                let index = newArray.findIndex((n) => (n.cat_id === category[i].id));
                if (index < 0) {
                    newArray.push({ cat_id: category[i].id, cat_name: category[i].name, products: [] });
                }
            }
            // console.log(newArray);
            products.forEach((p) => {
                if (p.category != undefined && p.category.length > 0) {
                    p.category.forEach((c) => {
                        let index = newArray.findIndex((n) => (n.cat_id === c.id));
                        if (index > 0 && newArray[index] != undefined)
                            newArray[index].products.push(p);
                    })
                }
            })
            setData(newArray);
        };
    }, [products]);

    const handleShowFilterChange = (event) => {
        setShow(!show)
    };

    let i = 0;
    return (
        <>
            <CRow className="mb-2">
                {/* <CCol md={2} className='filter-field mt-md-2 d-flex align-center'> */}
                <CCol md={2} className='filter-field mt-md-2 d-flex align-items-center'>
                    <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={(event) => handleShowFilterChange(event)} />
                </CCol>
                <CCol md={3} className='filter-field mt-md-2'>
                    <div className="w-100">Ціни в Excel</div>
                    {/* <a href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Вивантажити файл з цінами</a> */}
                    <Form className="d-flex" action={`${process.env.REACT_APP_SERVER_URL}/get-prices`} method="get">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle variant="grey" id="dropdown-basic" style={{ width: "170px", border: "1px solid grey" }}>
                                Виберіть категорії
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: "400px", width: "300px", overflow: "auto", paddingRight: "5px" }}>
                                {/* <Dropdown.Item href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Всі категорії</Dropdown.Item> */}
                                {
                                    (loader == false && filetredCategories.length > 0) &&
                                    filetredCategories.map((c) => {
                                        return (
                                            <Fragment key={c.cat_id}>
                                                <Form.Check className="ms-2" type="checkbox" name="category[]" label={c.cat_name} value={c.cat_id} id={`category-${c.cat_id}`} />
                                                {/* <Dropdown.Item key={c.cat_id} href={`${process.env.REACT_APP_SERVER_URL}/get-prices?category_id=${c.cat_id}`}>{c.cat_name}</Dropdown.Item> */}
                                            </ Fragment>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="success" type="submit">
                            <HiOutlineDownload />
                        </Button>
                    </Form>
                </CCol>
                <CCol md={3} className='filter-field mt-md-2'>
                    <div className="w-100">Товарний Фід XML</div>
                    {/* <a href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Вивантажити файл з цінами</a> */}
                    <Form className="d-flex" action={`${process.env.REACT_APP_SERVER_URL}/products/export-xml`} method="get">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle variant="grey" id="dropdown-basic" style={{ width: "170px", border: "1px solid grey" }}>
                                Виберіть категорії
                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu style={{ maxHeight: "400px", overflow: "auto", paddingRight: "5px" }}> */}
                            <Dropdown.Menu style={{ maxHeight: "400px", width: "300px", overflow: "auto", paddingRight: "5px" }}>
                                {/* <Dropdown.Item href={`${process.env.REACT_APP_SERVER_URL}/get-prices`}>Всі категорії</Dropdown.Item> */}
                                {
                                    (loader == false && filetredCategories.length > 0) &&
                                    filetredCategories.map((c) => {
                                        return (
                                            <>
                                                <Form.Check className="ms-2" type="checkbox" name="category[]" label={c.cat_name} value={c.cat_id} id={`category-${c.cat_id}`} />
                                                {/* <Dropdown.Item key={c.cat_id} href={`${process.env.REACT_APP_SERVER_URL}/get-prices?category_id=${c.cat_id}`}>{c.cat_name}</Dropdown.Item> */}
                                            </>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="success" type="submit">
                            <HiOutlineDownload />
                        </Button>
                    </Form>
                </CCol>
            </CRow>

            <p>Щоб не нагружати сторінку всіма товарами, виводяться тільки ті товари які мають галочку "Товар продається", або ціну більше нуля. Якщо якоїсь позиції нема перевірте ціну або мітку продається.</p>

            {(loader == false && filetredCategories.length > 0) ?
                filetredCategories.map((c) => {
                    // console.log('c.cat', c);
                    return (
                        <div key={c.cat_id}>
                            <h3>{c.cat_name}</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="prices-number__th">#</th>
                                        <th>Назва</th>
                                        <th className="prices-text__th">Продається</th>
                                        <th className="prices-text__th">Артикул</th>
                                        {/* <th className="prices-text__th">Код 1с</th> */}
                                        {/* <th className="prices-income-price__th">Прихідна ціна</th> */}
                                        <th className="prices-price__th">Ціна</th>
                                        {/* <th>Гурт 3</th> */}
                                        {/* <th>Гурт 2</th> */}
                                        {/* <th>Гурт 1</th> */}
                                        {/* <th>Vip</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(c.products != undefined) && c.products.map((p) => {
                                        i++;
                                        return (
                                            <tr key={p.id}>
                                                <td className="prices-number__td">{i}</td>
                                                <td>{p.name}</td>
                                                <td className="prices-text__td">{(p.show === true) ? 'так' : 'ні'}</td>
                                                <td className="prices-text__td">{p.article}</td>
                                                {/* <td className="prices-text__td">{p.code1c}</td> */}
                                                {/* <td className="prices-income-price__td">{p.priceIncome}</td> */}
                                                <td className="prices-price__td">{p.price}</td>
                                                {/* <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_4}</td> */}
                                                {/* <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_3}</td> */}
                                                {/* <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_2}</td> */}
                                                {/* <td>{(p.priceCategory != undefined && p.priceCategory != null) && p.priceCategory.gurt_1}</td> */}
                                            </tr >
                                        )
                                    })}
                                </tbody >
                            </Table >
                        </div >
                    )
                })
                :
                <>
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="info" />
                </>
            }
        </>
    );
};

export default Prices;