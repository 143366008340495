import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";

export default function usePropoziciyiCategory() {
   const [arrOfIgnoredCategories, setArrOfIgnoredCategories] = useState([])
   const [isLoading, setIsLoading] = useState(false)
   const ApiParams = {
      "g[0]": "cat:read",
      "g[1]": "cat:admin",
      "g[2]": "cat:categories"
   }
   const getPropoziciyiCategory = async () => {
      setIsLoading(true)
      try {
         const response = await AxiosInstance.get(`/categories/56`, { params: ApiParams });
         const ignoredCategories = [response.data.id, ...(response.data.categories?.length > 0 && response.data.categories.filter(elem => elem.type === "taxonomy").map(e => e.id))]
         setArrOfIgnoredCategories(ignoredCategories)
      } catch (error) {
         console.error("Помилка запиту за даними категоріі: ", error);
      }
      finally {
         setIsLoading(false)
      }
   };

   useEffect
      (() => {
         getPropoziciyiCategory()
      }, [])

   return { arrOfIgnoredCategories, isLoading }

}