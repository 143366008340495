import React, { useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { _nav_admin, _nav_manager } from '../_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [nav, setNav] = useState([]);
  const [userRole, setUserRole] = useState(false);

  /** Logo */
  const Logo = process.env.REACT_APP_LOGO;
  const brand_color = process.env.REACT_APP_BRAND_COLOR;
  const [logo, setLogo] = useState((Logo == undefined)? false: Logo);
  const [brandColor, setBrandColor] = useState(brand_color != undefined ? brand_color : '#000')
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Автовідтворення перервано:", error);
        });
      }
    };

    // Встановлюємо інтервал для повторного запуску відео
    const interval = setInterval(() => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Помилка відтворення:", error);
        });
      }
    }, 20000); // Кожні 20 секунд

    playVideo(); // Автовідтворення при завантаженні

    return () => clearInterval(interval); // Очищення інтервалу при демонтованні
  }, []);
  




  useEffect(() => {
    if (!userRole)
      setUserRole(localStorage.getItem('user_role'));
    if (userRole == 'ROLE_ADMIN' || userRole == 'ROLE_ADMIN,ROLE_USER')
      setNav(_nav_admin)
    if (userRole == 'ROLE_MANAGER')
      setNav(_nav_manager)
  }, [userRole])

  // console.log(typeof nav);
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/" style={{ backgroundColor: brandColor }}>
        {(logo)?
            <img src={`/${logo}`} width={115} />
          :
          <div className="video-container">
            <video
              className="responsive-video"
              ref={videoRef}
              autoPlay
              muted
              playsInline
              loop={false} // Не зациклювати
              preload="auto" // Завантажити відео один раз при завантаженні сторінки
              style={{width: '100%'}}
            >
              <source src="/logoAsd.mp4" type="video/mp4" />
              Ваш браузер не підтримує відео.
            </video>
          </div>
        } 

        
        {/* <img src="/logo.png" width={200} /> */}
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
        {/* <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav> 
        <SimpleBar>
          {(typeof nav == 'object') && <AppSidebarNav items={nav} />}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
