import React, { useState, useEffect } from 'react';

export default function useLocalStorage(key) {
   const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
   });

   useEffect(() => {
      const storedValue = localStorage.getItem(key);
      setValue(storedValue ? JSON.parse(storedValue) : '');
   }, [key]);


   useEffect(() => {
      const handleStorageChange = (event) => {
         if (event.key === key) {
            const newValue = event.newValue ? JSON.parse(event.newValue) : '';
            setValue(newValue);
         }
      };



      window.addEventListener('storage', handleStorageChange);

      return () => {
         window.removeEventListener('storage', handleStorageChange);
      };
   }, [key]);

   // const setStoredValue = (newValue) => {
   //    setValue(newValue);
   //    localStorage.setItem(key, JSON.stringify(newValue));
   // };

   // return [value, setStoredValue];

   return value
}