import React, { useState, useEffect } from 'react';
import { CButton, CForm, CFormCheck } from '@coreui/react';
import AxiosInstance from '../Axios';
import Accordion from 'react-bootstrap/Accordion';
import { sortObj } from "../store/sortObj";

// Компонент атрибутів для продукту
const ProductAttributes = (props) => {
    // console.log("ProductAttributes props", props);

    const urlApi_1 = '/attributes';
    const urlApi_2 = '/products';
    // const page = 1;
    const ApiParams = {
        "g[0]": "product:read",
        "g[1]": "product:admin",
        "g[2]": "product:attributeItems"
    };

    const [attributes, setAttributes] = useState([]); // Всі атрибути
    const [attributeItems, setAttributeItems] = useState(props.attributeItems); // Варіації, які вже додані до продукту
    const [productAttributes, setProductAttributes] = useState([]); // Атрибути, які додані до продукту
    // // id варіацій (при старті сторінки), в яких checkbox=true
    // const [checkedValue, setValue] = useState([]);
    const [checkbox, setCheckbox] = useState([]); // Варіації, в яких checkbox=true
    const [dataResp, setDataResp] = useState([]); // id варіацій, які додадуться до продукту і відправляться (PUT) на сервер 
    const [categories, setCategories] = useState(props?.categories ?? []);
    const [filteredCategories, setFilteredCategories] = useState(null);

    // Текст для popup
    const popupTextSuccess = "Атрибути збережено";

    // Функція для запиту за атрибутами
    const getAttributes = async (attributeId = null) => {
        let params = {
            main: true,
            pagination: false,
        };
        if (attributeId) {
            params = {
                ...params,
                "category.id": attributeId,
            };
        };

        try {
            const response = await AxiosInstance.get(urlApi_1, { params: params });
            // console.log("getAttributes response", response)
            setAttributes(response.data['hydra:member']);
        } catch (error) {
            console.error("Помилка запиту за атрибутами: ", error);
        };
    };

    // Відфільтрувати категорії з типом "category"
    useEffect(() => {
        // console.log('categories', categories);
        let filteredCategoriesArr = [];
        if (categories.length > 0) {
            filteredCategoriesArr = categories.filter(category => category.type === "category");
        };
        // console.log('filteredCategoriesArr', filteredCategoriesArr);
        setFilteredCategories(filteredCategoriesArr);
    }, [categories]);

    // Зробити запит за атрибутами
    useEffect(() => {
        // console.log('filteredCategories', filteredCategories);
        if (props?.mainCategoryId) {
            getAttributes(props.mainCategoryId);
        } else if (filteredCategories?.length > 0) {
            getAttributes(filteredCategories[0].id);
        } else if (filteredCategories !== null && filteredCategories.length === 0) {
            getAttributes();
        };
    }, [filteredCategories]);

    // Наповнення масива Атрибутів і вкладених Варіацій відповідно до attribute.id
    useEffect(() => {
        if (attributeItems !== undefined) {
            let attr = [];
            attributeItems.forEach((el) => {
                if (el.attribute != null) {
                    if (attr[el.attribute.id] === undefined) {
                        attr[el.attribute.id] = {
                            id: el.attribute.id,
                            name: el.attribute.name,
                            variations: []
                        };
                    };
                    attr[el.attribute.id]['variations'].push({
                        id: el.id,
                        name: el.name,
                        slug: el.slug
                    });
                }
            });
            // console.log("attr", attr);
            setProductAttributes(attr);
        }
    }, [attributeItems]);

    // Формування data_response
    useEffect(() => {
        let newDataResp = [];
        if (checkbox.length > 0) {
            // console.log("checkbox.length > 0", checkbox.length);
            checkbox.map((el) => newDataResp.push(`api/attribute_items/${el.id}`));
        } else {
            // console.log("checkbox.length = 0", checkbox.length);
            newDataResp = {};
        }
        // console.log("newDataResp", newDataResp);
        // Додати елемент до масива, який піде на сервер для оновлення варіацій для продукту
        setDataResp(newDataResp);
    }, [checkbox]);

    // Відправлення форми оновлення варіацій продукту
    const handleSubmit = event => {
        event.preventDefault();
        const data_response = {
            attributeItems: dataResp
        }
        if (props.productId != undefined) {
            AxiosInstance.put(`${urlApi_2}/${props.productId}`, data_response, { params: ApiParams }).then((response) => {
                // console.log('response', response);
                if (response.status === 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextSuccess);
                    // console.log("put response", response);
                    // Оновити дані
                    if (props.type === "products") {
                        setAttributeItems(response.data.attributeItems);
                    };
                    if (props.type === "site-products") {
                        props.getData();
                    };
                };
            });
        };
    };

    // При старті додати варіації до продукту
    useEffect(() => {
        // console.log("111 attributeItems", attributeItems)
        if (attributeItems != undefined && attributeItems.length > 0) {
            // Масив з id для проставляння checkbox=true
            let parseArr = [];
            // Масив з елементами, в яких el.checked = "true"
            let parseArrEl = [];
            // Проходимо по атрибутах товару
            attributeItems.forEach(el => {
                // Ставимо позначку, що цей атрибут чекнутий
                el["checked"] = true;
                parseArrEl.push(el);
                parseArr.push(el.id);
            })
            // Додаємо в масив з варіаціями, в яких checkbox=true
            setCheckbox(parseArrEl);
            // // Додаємо в масив з id, в яких checkbox=true
            // setValue(parseArr);
        };
    }, [attributeItems]);

    // Функція для зміни значення checked
    const sheckedChange = (el, defaultValue) => {
        // console.log(" sheckedChange checkbox", checkbox);
        // Якщо немало значень (el.checked), берем по дефолту (при старті сторінки прийшли варіації з сервера defaultValue=true, інші defaultValue=false)
        if (el.checked === undefined) {
            // Ставимо позначку, що цей атрибут чекнутий (el.checked = true) / нечекнутий (el.checked = false)
            el["checked"] = !defaultValue;
            // Якщо defaultValue = true --> el.checked = false
            if (defaultValue) {
                // Видалити елемент з масива з варіаціями, в яких checkbox=true
                deleteOrder(el.id);
            } else {
                // Додати елемент в масив з варіаціями, в яких checkbox=true
                addToOrder(el);
            }
        } else if (el.checked === true) {
            // Змінити значення поля checked на протилежне
            el["checked"] = !el.checked;
            // Видалити елемент з масива з варіаціями, в яких checkbox=true
            deleteOrder(el.id);
        } else {
            // Змінити значення поля checked на протилежне
            el["checked"] = !el.checked;
            // console.log("sheckedChange deleteOrder el", el);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            addToOrder(el);
        }
    }

    // Додавання варіацій до масиву + перевірка чи вже додано
    const addToOrder = (item) => {
        // console.log("addToOrder_1 item", item);
        // чи товар вже є в корзині
        let isInArray = false;
        // Перевірка чи товар вже є в корзині
        if (checkbox.length > 0) {
            checkbox.forEach(el => {
                // console.log("addToOrder_1");
                if (el.id === item.id) {
                    isInArray = true;
                }
            })
        }
        // Якщо масив з варіаціями, в яких checkbox=true порожній і товара немає в корзині, то додати
        if (checkbox === undefined && !isInArray) {
            // console.log("addToOrder_2", item);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            setCheckbox([item]);
        } else if (!isInArray) {
            // console.log("addToOrder_3", item);
            // Додати елемент в масив з варіаціями, в яких checkbox=true
            setCheckbox([...checkbox, item]);
        }
    }

    // Видалення товарів з корзини
    const deleteOrder = (id) => {
        // console.log("deleteOrder item", id);
        setCheckbox(checkbox => checkbox.filter(el => el.id !== id));
    }

    return (
        <>
            {
                (props.type === "site-products") ?
                    <h5>Атрибути</h5> :
                    <div className='d-flex'><h3 className='mr-5'>Атрибути</h3> <a className='ml-5' href="/attributes/create" target='_blanck'>Створити</a></div>
            }
            <CForm onSubmit={handleSubmit}>
                <Accordion>
                    {
                        (attributes.length > 0) &&
                        sortObj(attributes, 'sort').map((attribute, index) => {
                            // console.log('attribute', attribute);
                            // Атрибути
                            return (
                                <Accordion.Item key={attribute.id} eventKey={index}>
                                    <Accordion.Header>
                                        <strong>{attribute.name}</strong>
                                        {
                                            (productAttributes.length > 0 && props.type !== "site-products") &&
                                            <>
                                                {
                                                    productAttributes.map((elem) => {
                                                        if (attribute.id === elem.id) {
                                                            return (
                                                                <span key={elem.id}>: <span style={{ color: "transparent" }}>a</span>
                                                                    {
                                                                        elem.variations.map((variation, index) => {
                                                                            return (
                                                                                <span key={index}>
                                                                                    {variation.name}
                                                                                    {(index !== (elem.variations.length - 1)) ? ", " : ""}
                                                                                </span>
                                                                            )
                                                                        })
                                                                    }
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                        }
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            (attribute.attributeItems.length > 0) &&
                                            attribute.attributeItems.map(variation => {
                                                // Варіації
                                                // При старті сторінки проставити checked для варіацій, які додані до продукту 
                                                // let d = (checkedValue.includes(variation.id)) ? true : false; // закоментовано, оскільки при старті відображає правильно, але при зміні візуально не змінює, але при зберіганні заповнить правильно. Після оновлення сторінки покаже правильно
                                                let d = checkbox.some(item => item.id === variation.id) ? true : false;
                                                return (
                                                    <div className="ms-2 my-2" key={variation.id}>
                                                        <CFormCheck label={variation.name} defaultChecked={d} onChange={() => sheckedChange(variation, d)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
                <CButton type="submit" className="my-3" color="success">Зберегти атрибут</CButton>
            </CForm>
        </>
    )
}

export default ProductAttributes;