import React, { useEffect, useRef, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import AxiosInstance from '../Axios';
import useLocalStorage from '../store/useLocalStorage';

// Вкладка "Загальні"
const ProductGeneral = (props) => {

    const [name, setName] = useState((props.data != undefined) ? props.data.name : '');
    const [code1c, setCode1c] = useState((props.data != undefined) ? props.data.code1c : '');
    const [barcode, setBarcode] = useState((props.data != undefined) ? props.data.barcode : '');
    const [status, setStatus] = useState((props.data != undefined) ? props.data.status : '');
    const [article, setArticle] = useState((props.data != undefined) ? props.data.article : '');
    const [packaging, setPackaging] = useState((props.data != undefined) ? props.data.packaging : '');
    const [nds, setNds] = useState((props.data != undefined) ? props.data.nds : '');
    const [warrantyPeriod, setWarrantyPeriod] = useState((props.data != undefined) ? props.data.warrantyPeriod : '');
    //блок для завантаження ключа tiny
    const watchedItem = useLocalStorage('tinyKey')
    const [tinyKey, setTinyKey] = useState(watchedItem || '')
    /////////////////////////////////
    const [storageElement, setStorageElement] = useState((props.data != undefined && props.data.storageElement != null) ? { label: props.data.storageElement.name, value: props.data.storageElement.id } : {});
    const [defaultStorageElement, setDefaultStorageElement] = useState([]);


    const [measurmentUnit, setMeasurmentUnit] = useState(
        (props.data != undefined && props.data.measurement_unit != null) ?
            { label: props.data.measurement_unit.name, value: props.data.measurement_unit.id }
            :
            {}
    );

    //флаг для відслідковування процессу завантаження одиниць виміру
    const [isMeasurmentListLoading, setIsMeasurmentListLoading] = useState(false)

    const [defaultMeasurmentUnit, setDefaultMeasurmentUnit] = useState(null)
    useEffect(() => {
        if (Object.keys(measurmentUnit).length === 0 && Array.isArray(defaultMeasurmentUnit) && defaultMeasurmentUnit.length > 0) {
            setMeasurmentUnit(defaultMeasurmentUnit[0]);
        };
    }, [measurmentUnit, defaultMeasurmentUnit]); // залежності

    const editorRef = useRef(null);
    // Ключ для Editor
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

    const handleChange = (value) => {
        setStorageElement(value);
    };

    // Для зміни значеь "Одиниці виміру"
    const handleChangeMeasurmentUnit = (value) => {
        setMeasurmentUnit(value);
    };

    useEffect(() => {
        storageElementList();
        measurmentUnitList();
    }, []);

    useEffect(() => {
        if (watchedItem) {
            setTinyKey(watchedItem)
        }
    }, [watchedItem])

    const storageElementList = async (inputValue) => {
        let rez = await AxiosInstance.get(`/storage_elements`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
                contacts: result.contacts
            }));
        });
        setDefaultStorageElement(rez);
        return rez;
    };

    // Запит за одиницями виміру
    const measurmentUnitList = async (inputValue) => {
        // let rez = await AxiosInstance.get(`/measurment_units`, { headers: { 'accept': 'application/json' } }).then((response) => {
        //     // console.log("response", response);
        //     return response.data.map((result) => ({
        //         label: result.name,
        //         value: result.id,
        //     }));
        // })
        // setDefaultMeasurmentUnit(rez);
        // return rez;
        try {
            setIsMeasurmentListLoading(true)
            const response = await AxiosInstance.get(`/measurment_units`, { headers: { 'accept': 'application/json' } })
            const rez = response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }))
            setDefaultMeasurmentUnit(rez)

            return rez;

        } catch (error) {
            console.log(error)
        } finally {
            setIsMeasurmentListLoading(false)
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (editorRef.current) {
            const data_response = {
                active: 'on',
                name: name,
                description: editorRef.current.getContent(),
                code1c: code1c,
                barcode: barcode,
                // status: status,
                article: article,
                warrantyPeriod: warrantyPeriod,
                // nds: parseFloat(nds),
                // packaging: parseFloat(packaging),
                measurement_unit: `/api/measurment_units/${measurmentUnit.value}`,
                // if(storageElement.value != undefined)
                // data_response['storageElement'] = `/api/storage_elements/${storageElement.value}`;
                storageElement: (storageElement.value != undefined) ? `/api/storage_elements/${storageElement.value}` : null,
                ...((props.data === undefined || props.data.minBalance === null || props.data.minBalance === undefined) && { minBalance: 1 }),
                ...((props.data === undefined || props.data.minBalanceAdmin === null || props.data.minBalanceAdmin === undefined) && { minBalanceAdmin: 1 })

            };

            props.handleSubmit(event, data_response);
        };
    };


    return (
        <div>
            {!isMeasurmentListLoading && defaultMeasurmentUnit !== null && defaultMeasurmentUnit?.length === 0 && <p className='alert alert-danger'>Увага! для того щоб створити товар потрібно мати список <strong>одиниць виміру</strong>! <a href='/measurment-unit'>Перейти на список</a></p>}
            {props.data == undefined && <p className='alert alert-warning'>
                <p className='h3'>Щоб створити товар заповніть мінімальну кількість обовязкових полів полів.</p>
                <ul>
                    <li>Назва</li>
                    <li>Одиниці виміру</li>
                    <li>Термін гарантії</li>
                </ul>
            </p>}
            <CForm onSubmit={handleSubmit}>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Назва*</CFormLabel>
                    {/* <CFormInput type="text" id="exampleFormControlInput1" value={props.name} onChange={(e) => props.setName(e.target.value)} autoComplete="off" /> */}
                    <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className="mb-3" style={{ zIndex: "101", position: "relative" }}>
                            <CFormLabel htmlFor="exampleFormControlInput1">Елемент Складу</CFormLabel>
                            <Select
                                // options={props.defaultStorageElement}
                                options={defaultStorageElement}
                                // value={props.storageElement}
                                value={storageElement}
                                // onChange={(value) => { props.handleChange(value) }}
                                onChange={(value) => { handleChange(value) }}
                            />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3" style={{ zIndex: "101", position: "relative" }}>
                            <CFormLabel htmlFor="exampleFormControlInput1">Одиниці виміру*</CFormLabel>
                            <Select
                                // options={props.defaultMeasurmentUnit}
                                options={defaultMeasurmentUnit}
                                // value={props.measurmentUnit}
                                value={measurmentUnit}
                                // onChange={(value) => { props.handleChangeMeasurmentUnit(value) }}
                                onChange={(value) => { handleChangeMeasurmentUnit(value) }}
                            />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Термін гарантії (роки)*</CFormLabel>
                            <CFormInput
                                type="text" id="exampleFormControlInput9" value={warrantyPeriod}
                                onChange={(e) => {
                                    const onlyNumbers = e.target.value.replace(/[^\d]/g, ''); // Видаляє всі нецифрові символи
                                    setWarrantyPeriod(onlyNumbers);
                                }}
                                autoComplete="off" required
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Артикул</CFormLabel>
                            {/* <CFormInput type="number" id="exampleFormControlInput9" value={props.article} onChange={(e) => props.setArticle(e.target.value)} autoComplete="off" /> */}
                            {/* <CFormInput type="number" id="exampleFormControlInput9" value={article} onChange={(e) => setArticle(e.target.value)} autoComplete="off" /> */}
                            <CFormInput type="text" id="exampleFormControlInput9" value={article} onChange={(e) => setArticle(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">code1c</CFormLabel>
                            {/* <CFormInput type="text" id="exampleFormControlInput3" value={props.code1c} onChange={(e) => props.setCode1c(e.target.value)} autoComplete="off" /> */}
                            <CFormInput type="text" id="exampleFormControlInput3" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Штрихкод</CFormLabel>
                            {/* <CFormInput type="text" id="exampleFormControlInput4" value={props.barcode == null ? "" : props.barcode} onChange={(e) => props.setBarcode(e.target.value)} autoComplete="off" /> */}
                            <CFormInput type="text" id="exampleFormControlInput4" value={barcode == null ? "" : barcode} onChange={(e) => setBarcode(e.target.value)} autoComplete="off" />
                        </div>
                    </div>

                </div>

                {/* <div className="mb-3">
                          <CFormLabel htmlFor="exampleFormControlInput1">nds</CFormLabel>
                          <CFormInput type="number" id="exampleFormControlInput10" value={nds} onChange={(e) => setNds(e.target.value)} autoComplete="off" />
                        </div> */}
                {/* <div className="mb-3">
                          <CFormLabel htmlFor="exampleFormControlInput1">packaging</CFormLabel>
                          <CFormInput type="number" id="exampleFormControlInput11" value={packaging} onChange={(e) => setPackaging(e.target.value)} autoComplete="off" />
                        </div> */}
                <div className="mb-3" style={{ zIndex: "100", position: "relative" }}>
                    <Editor
                        // apiKey={apiKey}
                        apiKey={tinyKey}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={(props.data != undefined) ? props.data.description : ''}
                        init={{
                            height: 500,
                            // menubar: false,
                            menubar: 'edit view insert format tools',
                            // plugins: [
                            //   'advlist autolink lists link image charmap print preview anchor',
                            //   'searchreplace visualblocks code fullscreen',
                            //   'insertdatetime media table paste code help wordcount'
                            // ],
                            plugins: [
                                'advlist', 'autolink',
                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
                            ],
                            // toolbar: 'undo redo | formatselect | ' +
                            //   'bold italic backcolor | alignleft aligncenter ' +
                            //   'alignright alignjustify | bullist numlist outdent indent | ' +
                            //   'removeformat | help',
                            toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                                'backcolor forecolor |' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist checklist outdent indent | removeformat | table help',
                            font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </div>
                <CButton className="mb-3" type="submit" color="success">Зберегти товар</CButton>
            </CForm>
        </div >
    );
};

export default ProductGeneral;