import React, { useEffect, useState } from 'react';
import { CButton, CForm, CFormLabel } from "@coreui/react";
import Select from 'react-select';
import AxiosInstance from '../Axios';
import usePropoziciyiCategory from '../store/usePropoziciyiCategory';


export default function AddMainCategory(props) {
   // console.log("props", props)
   const [filteredCategories, setFilteredCategories] = useState([])
   const [isSubmitting, setIsSubmitting] = useState(false)
   const changeMainCategorySuccess = "Головну категорію додано"
   const changeMainCategoryWarning = "Головну категорію не додано"
   const { arrOfIgnoredCategories, isLoading } = usePropoziciyiCategory()
   const ApiParams = {
      'g[0]': 'product:read',
      'g[1]': 'product:admin',
      'g[2]': 'product:category'
   }

   useEffect(() => {
      if (props.data.category?.length > 0 && arrOfIgnoredCategories?.length > 0) {
         setFilteredCategories(props.data.category.filter(elem => !arrOfIgnoredCategories.some(e => e === elem.id)))
      }
   }, [props.data.category, isLoading])


   const optionsCategories = filteredCategories.map(elem => {
      return ({ value: elem.id, label: elem.name })
   }) || []

   const handleMainCategorySubmit = async (event) => {
      event.preventDefault();

      try {
         if (!props.mainCategory.value) {
            console.error("Категорія не вибрана.");
            return;
         }
         await addMainCategory();

         console.log("Категорія успішно оновлена.");
      } catch (error) {
         console.error("Помилка при оновленні категоріі:", error);
      }
   }

   const addMainCategory = async () => {
      setIsSubmitting(true)
      try {
         const response = await AxiosInstance.put(`/products/${props.data.id}`, {
            mainCategoryId: props.mainCategory.value
         }, {
            headers: { accept: 'application/ld+json' },
            params: ApiParams
         });
         props.toastifySuccess(changeMainCategorySuccess)
         props.setMyData(response.data)
         // console.log("response.data", response.data)
         return response.data;
      } catch (error) {
         props?.toastifyWarning(changeMainCategoryWarning)
         console.error("Помилка при зміні  категоріі:", error);
      }
      finally {
         setIsSubmitting(false)
      }
   }

   const handleChangeMainCategory = (value) => {
      props.setMainCategory(value);
   };

   return (
      <>
         {filteredCategories?.length > 0 && <div>
            <hr className='mb4' />
            <CForm onSubmit={handleMainCategorySubmit}>
               <div className='row'>
                  <div className='col'>
                     <div className="mb-3" style={{ zIndex: "1", position: "relative", width: "50%" }}>
                        <CFormLabel htmlFor="exampleFormControlInput1">Вибрати головну категорію</CFormLabel>
                        <Select
                           options={optionsCategories}
                           value={props.mainCategory && props.mainCategory}
                           onChange={(value) => { handleChangeMainCategory(value) }}
                           placeholder="Оберіть категорію"
                        />
                     </div>
                     {!props.data.mainCategoryId && <p style={{ color: 'red' }}>Головна категорія не вибрана</p>}
                  </div>
               </div>
               <CButton className="mb-3" type="submit"
                  disabled={
                     isSubmitting || !props.mainCategory?.value || (props.data.mainCategoryId === props.mainCategory?.value)}
                  color="success">Зберегти </CButton>
            </CForm>
         </div >}
      </>
   )
}