import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import Badge from 'react-bootstrap/Badge'
import AddMainCategory from '../category/AddMainCategory';


// Додавання категорій до продукту
const ProductCategories = (props) => {
    const ApiParams = {
        "g[0]": "product:read",
        "g[1]": "product:admin",
        "g[2]": "product:category",
        "g[3]": "product:attributeItems",
        "g[4]": "product:media",
        "g[5]": "product:hover",
        "g[6]": "product:isNew",
        // "g[6]": "product:productInfos"
    };
    // console.log("props", props);
    // props.product_id - id-продукту

    // Категорії, які додані до продукту
    const [catItem, setCatItem] = useState([]);
    // Категорії, які додані до продукту, записані у форматі PUT (`api/categories/id`) для відправлення
    const [oldCat, setOldCat] = useState([]);
    // Вибрана категорія з пошуку
    const [selectedOption, setSelectedOption] = useState(null);
    //стани для для isNew
    // const [isNew, setIsNew] = useState(false)
    const [mainCategory, setMainCategory] = useState((props.data.category?.length > 0 && props.data.mainCategoryId && props.data.mainCategoryId > 0)
        ? { value: props.data.mainCategoryId, label: props.data.category?.filter(elem => elem.id === props.data.mainCategoryId)[0]?.name } : null);
    // Текст для popup
    const popupTextAddSuccess = "Категорію додано";
    const popupTextDeleteSuccess = "Категорію видалено";

    let i = 0;
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const getCategoryWithParents = (result) => {
        let n = '';
        if (result.parent != undefined) {
            n += ` -> ${result.parent.name}`;
            n += getCategoryWithParents(result.parent)
        }
        return n;
    }
    // Завантаження всіх категорій для вибору з випадаючого списку
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/categories`, {
            headers: { 'accept': 'application/json' }, params: {
                itemsPerPage: 1000,
                name: inputValue,
                'g[0]': 'cat:read',
                'g[1]': 'cat:parent',


            }
        }).then((response) => {
            // console.log(response.data)
            return response.data.map((result) => {
                let parent = getCategoryWithParents(result);
                let n = result.name + parent;

                return {
                    label: n,
                    value: result.id,
                }
            });
        });
    };

    // Запит за категоріями, які вже додані до продукту
    const getParent = async () => {
        AxiosInstance.get(`/categories`, {
            headers: { 'accept': 'application/json' }, params: {
                "products.id": props.product_id,
                'g[0]': 'cat:read',
                'g[1]': 'cat:parent',
            }
        }).then((response) => {
            // console.log("response get products", response);
            setCatItem(response.data)
        });
    }
    useEffect(() => {
        getParent();
    }, [props.data.category]); // }, [props]); // залежність від props не можна, оскільки при активності на інших вкладках (зміна інпута, checkbox) кожного разу спрацьовує useEffect

    // console.log("catItem для продукту", catItem)

    // Заповнити категорії, які додані до продукту, записані у форматі PUT (`api/attributs/id`) для відправлення
    useEffect(() => {
        if (catItem.length > 0) {
            let oldCategories = [];
            // Наповнити масив з категорями, перетвеними у формат для API
            catItem.map(el => oldCategories.push(`api/categories/${el.id}`));
            // console.log("oldCategories", oldCategories);
            setOldCat(oldCategories);
        }
        // console.log("catItem.categories", catItem);
    }, [catItem]);

    // Додати нову категорію
    const handleSave = () => {
        // console.log('handle save')
        // Перевірка, чи вибрана категорія вже належить до даного продукту
        let enters = catItem.length > 0 ? catItem.some(el => el.id == selectedOption?.value) : false;
        if (enters) {
            alert("Вибрана категорія вже належить до даного продукту");
        } else {
            // Вибрана нова категорія
            let newCategories = [`api/categories/${selectedOption.value}`];
            // Об'єднати старі категорії (якщо є) і нову категорію
            let categories = oldCat.length > 0 ? oldCat.concat(newCategories) : newCategories;



            // Дані, які підуть із запитом
            let article = {
                category: categories,
                ...Number(selectedOption?.value) === 55 ? ({ isNew: true }) : {},
                ...Number(selectedOption?.value) === 70 ? ({ isSalle: true }) : {},

            };
            // Оновити категорії для продукту
            AxiosInstance.put(`/products/${props.product_id}`, article, { params: ApiParams }).then((response) => {
                // console.log("response put", response);
                if (response.status == 200) {
                    if (Number(selectedOption?.value) === 55) {
                        props.setIsNew(true)
                    }
                    if (Number(selectedOption?.value) === 70) {
                        props.setIsSalle(true)
                    }

                    props.setMyData(response.data)
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextAddSuccess);
                    // alert("Дані оновлено");
                    getParent();
                }
            });
        };
    };

    // Видалити категорію від продукту
    const deleteCategory = (id) => {
        // console.log('handle delete')
        let categories = [];
        // Відфільтрувати категорію, яку потрібно видалити
        let filterCategories = catItem.filter(el => el.id !== id);
        // Наповнити масив з категорями, перетвеними у формат для API
        filterCategories.map(el => categories.push(`api/categories/${el.id}`));
        // Дані, які підуть із запитом
        let article = {
            category: categories,
            ...Number(id) === 55 ? ({ isNew: false }) : {},
            ...Number(id) === 70 ? ({ isSalle: false }) : {},
            ...Number(id) === props.data.mainCategoryId ? ({ mainCategoryId: null }) : {}

        };

        // Оновити категорії для продукту
        AxiosInstance.put(`/products/${props.product_id}`, article, { params: ApiParams }).then((response) => {
            // console.log("response put", response);
            if (response.status == 200) {
                if (Number(selectedOption?.value) === 55) {
                    props.setIsNew(false)
                }
                if (Number(selectedOption?.value) === 70) {
                    props.setIsSalle(false)
                }
                if (Number(id) === props.data.mainCategoryId) {
                    setMainCategory(null)
                }
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.setMyData(response.data)
                props.toastifySuccess(popupTextDeleteSuccess);
                setSelectedOption(null)
                getParent();
            }
        });
    };

    // Видалити категорію від продукту
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log("delete row id", row);
                        deleteCategory(row);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    // console.log("props", props)
    // console.log('selectedOption', selectedOption?.value)
    // console.log("catItem", catItem)
    // console.log('props.data.isNew', props.data.isNew)
    return (
        <>
            <div className="row">
                <div className="col d-flex"><h3>Категорії</h3>  <a className='ml-5' href="/category/create" target='_blanck'>Створити</a></div>
                <div className="col d-flex">
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        value={selectedOption}
                        onChange={handleChange}
                        className="flex-grow-1 me-2"
                    />
                    <CButton onClick={handleSave} className="mb-2" color="secondary">+</CButton>
                </div>
            </div>

            {/* Список з доданими категоріями */}
            {
                (catItem.length > 0) ?
                    <CListGroup className="mb-3">
                        {
                            catItem.map((v, k) => {
                                i++;
                                let p = getCategoryWithParents(v);
                                return (
                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        {/* <Link to={`/products/update/${v.id}`} >{`${i})`} {v.name}</Link> */}
                                        <div>{`${i})`} <strong>{v.name}</strong> <Badge variant="light" className='bg-light text-secondary'> {p} </Badge></div>
                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v.id) }}>
                                            Видалити
                                        </CButton>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup>
                    :
                    <p className="ml-3">Категорій не знайдено</p>
            }
            <AddMainCategory
                data={props.data}
                setMyData={props.setMyData}
                toastifySuccess={props.toastifySuccess}
                toastifyWarning={props.toastifyWarning}
                setMainCategory={setMainCategory}
                mainCategory={mainCategory} />
        </>
    );
}

export default ProductCategories;