export default function useLocalTimeV1(date) {
   if (!date) return ''; // Захист від undefined/null

   const parseDate = new Date(date); // API повертає UTC, створюєм об'ект Date

   // Перетворюємо в локальний час
   const localDate = new Date(parseDate.getTime() - parseDate.getTimezoneOffset() * 60000);


   // Перевіряєм, чи є в дати час
   // const hasTime = parseDate.getUTCHours() !== 0 || parseDate.getUTCMinutes() !== 0 || parseDate.getUTCSeconds() !== 0;
   const hasTime = localDate.getHours() !== 0 || localDate.getMinutes() !== 0 || localDate.getSeconds() !== 0;
   //замінив в константах часу parseDate на localDate
   // Формуємо дату в форматі YYYY-MM-DD
   const year = localDate.getFullYear();
   const month = String(localDate.getMonth() + 1).padStart(2, '0');
   const day = String(localDate.getDate()).padStart(2, '0');

   if (!hasTime) {
      // return parseDate.toISOString().split('T')[0];
      return `${year}-${month}-${day}`;   // Повертаємо тільки дату в форматі YYYY-MM-DD
   }

   // Формуємо дату + локальний час в форматі YYYY-MM-DDTHH:MM
   const hours = String(localDate.getHours()).padStart(2, '0');
   const minutes = String(localDate.getMinutes()).padStart(2, '0');

   // Перетворюєм в локальний час в форматі YYYY-MM-DDTHH:MM
   // return parseDate.toISOString().slice(0, 16);
   return `${year}-${month}-${day}T${hours}:${minutes}`;
}
