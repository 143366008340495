import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import { Card } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
// import PageInfoForm from "./PageInfoForm";
import BasicModal from 'src/components/Modal';
import Button from 'react-bootstrap/Button';
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { confirmAlert } from "react-confirm-alert";
import { BsSortNumericDown } from 'react-icons/bs';
import { sortObj } from "../store/sortObj";
import { TypeSpecimenOutlined } from "@mui/icons-material";
import PageInfoForm from "./PageInfoForm";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const PageInfoList = ({ id, type }) => {
   // console.log('id', id)
   // console.log('type', type)

   const [pageInfos, setPageInfos] = useState([]);
   const [showModalItemUpdate, setShowModalItemUpdate] = useState(false);
   const [showModalItemNew, setShowModalItemNew] = useState(false);
   const [selectedData, setSelectedData] = useState(null); // Вибраний модуль для редагування
   const popup = MyToastify();

   const actionShowModal = (rez) => {
      setShowModalItemNew(rez);
   };

   const actionShowModalItemNew = (rez) => {
      setShowModalItemNew(rez);
      if (!rez) {
         // console.log("actionShowModalItemNew");
         getItems();
      }
   };

   // const actionShowModalItemUpdate2 = (rez) => {
   //     setShowModalItemUpdate(rez);
   // };

   const actionShowModalItemUpdate = (rez) => {
      setShowModalItemUpdate(rez);
      if (!rez) {
         getItems();
         setSelectedData(rez);
      };
   };

   const getItems = () => {
      let param = type === 'page' ? { ['page.id']: id } : { [type]: id };
      AxiosInstance.get(`/page_infos`, { params: param }).then((response) => {
         // console.log('/page_infos get response', response);
         if (response.status == 200)
            setPageInfos(response.data['hydra:member']);
      });
   };

   useEffect(() => {
      if (id != undefined) {
         getItems();
      };
   }, [id]);

   function Deleted(id) {
      confirmAlert({
         title: 'Ви впевнені що хочите видалити цей пункт ?',
         message: 'Are you sure to do this.',
         buttons: [
            {
               label: 'Так',
               onClick: () => {
                  const response = AxiosInstance.delete('/page_infos/' + id, header).then((response) => {
                     getItems();
                  });
               }
            },
            {
               label: 'Ні',
               onClick: () => {
                  return;
               }
            }
         ]
      });
   };

   // Відкривати вибраний модуль для редагування
   useEffect(() => {
      if (selectedData) {
         setShowModalItemUpdate(true);
      };
   }, [selectedData]);


   // console.log('showModalItemUpdate', showModalItemUpdate, 'selectedData ', selectedData)

   return (
      <Card className="mt-5" >
         <Card.Header>Дані сторінки</Card.Header>
         <Card.Body>

            {sortObj(pageInfos, 'sort').map((page) => {
               // console.log('page', page);
               return (
                  <Card key={page.id} body className="info-list">
                     {/* <BasicModal
                                show={showModalItemUpdate}
                                // actionShowModal={actionShowModalItemUpdate2}
                                actionShowModal={actionShowModalItemUpdate}
                                title={`Оновити модуль`}
                                btn_name={page.key}
                                btn_color="light"
                                content={
                                    <PageInfoForm
                                        // getItems={getItems}
                                        toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemUpdate} data={page} type={type} />
                                }
                            /> */}
                     {/* тут перевірити */}
                     <span onClick={() => {
                        setSelectedData(page)
                        setShowModalItemUpdate(true);
                     }} style={{ cursor: "pointer" }} title="Редагувати">{page.key}</span>
                     <div>
                        <CButton color="danger" variant="outline" onClick={() => { Deleted(page.id) }}>
                           <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                        </CButton>
                        <small className='SortPunkt'><BsSortNumericDown /> - {page.sort}</small>
                     </div>
                  </Card>
               );
            })}


            <hr />
            <BasicModal
               show={showModalItemNew}
               // actionShowModal={actionShowModalItemNew}
               actionShowModal={actionShowModal}
               title={`Додати модуль`}
               btn_name={<>+ додати</>}
               content={
                  <PageInfoForm
                     // getItems={getItems}
                     setPageInfos={setPageInfos}
                     pageInfos={pageInfos}
                     toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemNew} type={type} />
               }
            />
            {/* Модальне вікно для редагування модуля */}
            {(selectedData
               && showModalItemUpdate
            ) &&
               <div
                  style={{ display: 'none' }}
               > {/* приховати кнопку відкритого модуля */}
                  <BasicModal
                     show={showModalItemUpdate}
                     actionShowModal={actionShowModalItemUpdate}
                     title={`Оновити модуль`}
                     btn_name={selectedData.key}
                     btn_color="light"
                     content={<PageInfoForm
                        // getItems={getItems}
                        setPageInfos={setPageInfos} pageInfos={pageInfos}
                        toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItemUpdate} data={selectedData} type={type} />
                     }
                  />
               </div>
            }
         </Card.Body>

      </Card>
   );
}


export default PageInfoList;