import axios from 'axios'

const tokenStr = localStorage.getItem('user')

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + '/api',
  timeout: 35000,

  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${tokenStr}`,
    // 'accept': 'application/json'
  },
})

AxiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // console.log(11111);
  // console.log(config);
  if (config.method == 'patch') config.headers['Content-Type'] = 'application/merge-patch+json'
  if (config.method == 'post') config.headers['Content-Type'] = 'application/json'
  // console.log(config);
  return config
})

AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response
  },
  function (error) {
    if (error.response && error.code == 'ERR_NETWORK') {
      // alert('Помилка зєднання з сервером!')
      console.log(error)
      console.log(error.response)
    }

    return Promise.reject(error)
  },
)

export const isCancel = axios.isCancel;

export default AxiosInstance;