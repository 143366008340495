import { useEffect, useState } from "react"

export default function ViewComponentWithDelay({
    children, delay
}) {
    const [componentView, setComponentView] = useState(false)

    // const delay = 2000

    useEffect(() => {

        setComponentView(false)
        const timer = setTimeout(() => {
            setComponentView(true)
        }, delay)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            {componentView && children}
        </>
    )
}
